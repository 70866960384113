import { FC, useRef } from 'react';

import { useAnimateAppearing, useAnimateRotation } from './hooks/useAnimate';
import styles from './MainLoader.module.scss';

export const MainLoader: FC = () => {
  const mainLoaderRef = useRef(null);
  useAnimateRotation(mainLoaderRef);
  useAnimateAppearing(true, mainLoaderRef);
  return (
    <div className={styles.MainLoader} ref={mainLoaderRef}>
      <div className={styles.point}></div>
    </div>
  );
};
