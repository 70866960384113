import { FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { CurrentLanguageType } from 'types/types';
import {
  getCurrentLanguage,
  getIsLanguageSwitcherRolled,
  setCurrentLanguage,
} from 'redux/slices/main/exports';
import { useAnimateHighlighting } from './hooks/useAnimate';
import { classNames } from 'helpers/classNames';
import styles from './LanguageSelector.module.scss';

interface Props {
  languageSelectorData: CurrentLanguageType;
}

export const LanguageSelector: FC<Props> = memo(({ languageSelectorData }) => {
  const languageSelectorRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isLanguageSwitcherRolled = useAppSelector(getIsLanguageSwitcherRolled);
  const isCurrentLanguage = languageSelectorData === currentLanguage;
  useAnimateHighlighting(isCurrentLanguage, languageSelectorRef);
  const switchLanguage = () => {
    isLanguageSwitcherRolled &&
      !isCurrentLanguage &&
      dispatch(setCurrentLanguage(languageSelectorData));
  };
  return (
    <div
      className={classNames(styles.LanguageSelector, ['language-selector'], {
        [styles.current]: isCurrentLanguage,
      })}
      onClick={switchLanguage}
      ref={languageSelectorRef}
    >
      <span>{languageSelectorData}</span>
    </div>
  );
});
