import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { ACCENTS_THEME_COLOR, MAIN_THEME_COLOR, ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateHighlighting = (
  isThisPageCurrent: boolean,
  isLeadLinkActive: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const selectedColor = !isThisPageCurrent ? MAIN_THEME_COLOR : ACCENTS_THEME_COLOR;
  const cursorType = isThisPageCurrent || !isLeadLinkActive ? 'default' : 'pointer';
  const {
    timeline: highlightingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isThisPageCurrent, isLeadLinkActive]);
  const opacity = isLeadLinkActive || isThisPageCurrent ? 1 : 0;
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : 0.6;
  const appearingDuration = isFirstLoad
    ? ZERO_GSAP_DURATION
    : isLeadLinkActive
    ? duration * 4
    : duration;
  highlightingTimeline &&
    highlightingTimeline
      .to(scopeElement.current, {
        color: selectedColor,
        duration,
        onComplete: () => setIsFirstLoad(false),
      })
      .to(
        scopeElement.current,
        {
          opacity,
          duration: appearingDuration,
        },
        '<',
      )
      .to(
        scopeElement.current,
        {
          cursor: cursorType,
          duration: ZERO_GSAP_DURATION,
        },
        '<',
      );
};
