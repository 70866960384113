import { FC, memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

import { HOME_LOGO_SRC } from 'constants/assets-src';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import { useAnimateHighlighting } from './hooks/useAnimate';
import { RoutesEnum } from 'constants/routes';
import styles from './HomeButton.module.scss';

interface Props {
  isNavLinksGroupCurrent: boolean;
}

export const HomeButton: FC<Props> = memo(({ isNavLinksGroupCurrent }) => {
  const homeButtonRef = useRef(null);
  const navigate = useNavigate();
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  useAnimateHighlighting(isNavLinksGroupCurrent, homeButtonRef);

  const changeCurrentlyViewedPageToHome = () => {
    if (!currentlyViewedPage) return;
    navigate(RoutesEnum.ROOT_PATH_URL);
  };
  return (
    <div
      className={styles.HomeButton}
      onClick={changeCurrentlyViewedPageToHome}
      ref={homeButtonRef}
    >
      {/* <div className={styles.house}>
        <div className={styles.roof}></div>
      </div> */}
      <img draggable={false} src={HOME_LOGO_SRC} alt="a house with a triangle roof" />
    </div>
  );
});
