import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { GSAPEasingEnum } from 'constants/gsapConstants';

export const useAnimateRotation = (scopeElement: MutableRefObject<HTMLDivElement>) => {
  const { timeline: rotationTimeline } = useGSAPTimeline(false, undefined, scopeElement, []);
  rotationTimeline &&
    rotationTimeline
      .to(scopeElement.current, {
        duration: 2.5,
        rotate: -360,
        ease: GSAPEasingEnum.POWER1_IN_OUT,
      })
      .to(scopeElement.current, {
        duration: 2.5,
        rotate: 0,
        ease: GSAPEasingEnum.POWER1_IN_OUT,
      })
      .repeat(-1);
};

export const useAnimateAppearing = (
  isLoading: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const opacityValue = isLoading ? 1 : 0;
  const { timeline: appearingTimeline } = useGSAPTimeline(false, undefined, scopeElement, [
    isLoading,
  ]);
  appearingTimeline &&
    appearingTimeline.to(scopeElement.current, {
      duration: 5,
      opacity: opacityValue,
    });
};
