import { Route, Routes, Navigate } from 'react-router-dom';

import { MainPageRemastered } from 'pages/MainPageRemastered/MainPageRemastered';
import { RoutesEnum } from 'constants/routes';

export const useRoutes = () => {
  return (
    <Routes>
      <Route
        path={`${RoutesEnum.ROOT_PATH_URL}`}
        element={<MainPageRemastered currentRoute={'home'} />}
      ></Route>
      <Route
        path={`${RoutesEnum.SPORT_ASSISTANT_URL}`}
        element={<MainPageRemastered currentRoute={'assistant'} />}
      ></Route>
      <Route
        path={`${RoutesEnum.SIGN_IN_URL}`}
        element={<MainPageRemastered currentRoute={'signin'} />}
      ></Route>
      <Route
        path={`${RoutesEnum.SIGN_UP_URL}`}
        element={<MainPageRemastered currentRoute={'signup'} />}
      ></Route>
      <Route
        path={`${RoutesEnum.INFO_URL}`}
        element={<MainPageRemastered currentRoute={'info'} />}
      ></Route>
      <Route
        path={`${RoutesEnum.PROJECTS_URL}`}
        element={<MainPageRemastered currentRoute={'projects'} />}
      ></Route>
      <Route
        path={`${RoutesEnum.ABOUT_ME_URL}`}
        element={<MainPageRemastered currentRoute={'support'} />}
      ></Route>
      <Route path={'*'} element={<Navigate to={`${RoutesEnum.ROOT_PATH_URL}`} />}></Route>
    </Routes>
  );
};
