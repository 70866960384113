import { FC, memo, useRef, Children, cloneElement, ReactElement } from 'react';

import { ReactChildrenType } from 'types/types';
import { useAnimateNavLinksGroupSliding } from './hooks/useAnimate';
import { HomeButton } from './components/HomeButton/HomeButton';
import styles from './NavLinksGroup.module.scss';

interface Props {
  mainChild: ReactElement;
  children?: ReactChildrenType;
  isNavLinksGroupCurrent: boolean;
}

export const NavLinksGroup: FC<Props> = memo(({ mainChild, children, isNavLinksGroupCurrent }) => {
  const linksWrapperRef = useRef(null);
  useAnimateNavLinksGroupSliding(isNavLinksGroupCurrent, linksWrapperRef);
  return (
    <div className={styles.NavLinksGroup}>
      <div className={styles.homeButtonWrapper}>
        <HomeButton isNavLinksGroupCurrent={isNavLinksGroupCurrent} />
      </div>
      <div className={styles.linksWrapper} ref={linksWrapperRef}>
        {cloneElement(mainChild, { ...mainChild.props, isNavLinksGroupCurrent })}
        <div className={styles.secondaryLinksWrapper}>
          {Children.map(children, (child) =>
            cloneElement(child, { ...child.props, isNavLinksGroupCurrent }),
          )}
        </div>
      </div>
    </div>
  );
});
