import { FC } from 'react';

import { CustomText } from './components/CustomText/CustomText';
import styles from './TextStyled.module.scss';

interface Props {
  text: string;
}

export const TextStyled: FC<Props> = ({ text }) => {
  return (
    <p className={styles.TextStyled}>
      {text.split(' ').map((textWord, index) => {
        if (textWord.startsWith('*')) {
          return <CustomText type="gradient" text={` ${textWord.slice(1)} `} key={index} />;
        }
        if (textWord.startsWith('~')) {
          return <CustomText type="solid" text={` ${textWord.slice(1)} `} key={index} />;
        }
        return ` ${textWord} `;
      })}
    </p>
  );
};
