import { FC, memo, useRef } from 'react';

import { MainLogoLetter } from './components/MainLogoLetter/MainLogoLetter';
import { useAnimate } from './hooks/useAnimate';
import styles from './MainLogoLive.module.scss';

export const MainLogoLive: FC = memo(() => {
  const mainLogoLiveRef = useRef<HTMLDivElement>();
  useAnimate(mainLogoLiveRef);
  return (
    <div className={styles.MainLogoLive} ref={mainLogoLiveRef}>
      <MainLogoLetter letterData={'D'} />
      <MainLogoLetter letterData={'E'} />
      <MainLogoLetter letterData={'G'} />
      <MainLogoLetter letterData={'U'} />
      <MainLogoLetter letterData={'Z'} />
    </div>
  );
});
