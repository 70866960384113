import { FC, memo, useRef } from 'react';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import styles from './RegistrationPage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const RegistrationPage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const registrationPageRef = useRef(null);
  useAnimateAppearing('signup', currentlyViewedPage, registrationPageRef);
  return (
    <div className={styles.RegistrationPage} ref={registrationPageRef}>
      <span style={{ fontWeight: 'bold' }}>registration</span>
    </div>
  );
});
