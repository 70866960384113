import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getTheme, setTheme } from 'redux/slices/main/exports';
import { DarkThemeButton } from './components/DarkThemeButton/DarkThemeButton';
import styles from './ThemeSwitcher.module.scss';

export const ThemeSwitcher: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(getTheme);

  const changeTheme = () => {
    dispatch(setTheme(theme === 'light' ? 'dark' : 'light'));
  };
  return (
    <div className={styles.ThemeSwitcher}>{theme === 'dark' ? <DarkThemeButton /> : null}</div>
  );
};
