import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import { App } from 'components/App';
import { ErrorBoundary } from 'pages/ErrorPage/ErrorBoundary';
import { store } from './redux/store';
import { IS_PRODUCTION } from 'constants/constants';
import { socket } from 'config/websockets/socket';
import './index.scss';

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App socket={socket} />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
if (IS_PRODUCTION) {
  disableReactDevTools();
}
const rootElement = document.getElementById('root')!;
createRoot(rootElement).render(app);
