import { FC, memo, useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../redux/hooks';

import { useRoutes } from '../hooks/useRoutes/useRoutes';
import { useLocalStorageSave } from '../hooks/useLocalStorageSave';
import { useLocalStorageLoad } from '../hooks/useLocalStorageLoad';
import { WebSocketType } from 'types/types';
import {
  getCurrentLanguage,
  getIsAuthModalActive,
  getIsPasswordRecoveryMode,
  getIsPasswordRecoveryProcessStarted,
  getPasswordRecoveringEmail,
  setIsPasswordRecoveryMode,
  setIsPasswordRecoveryProcessStarted,
  setPasswordRecoveringEmail,
  setOnlineUsersNicknames,
  setIsAuthModalActive,
} from 'redux/slices/main/exports';
import {
  getIsAuth,
  getRecoveryCodeTimerTimestamp,
  getRecoverSessionTimerTimestamp,
  getRecoverySessionId,
  setRecoverySessionId,
  setRecoveryCodeTimerTimestamp,
  setRecoverSessionTimerTimestamp,
} from 'redux/slices/user/exports';
import { MainLoader } from './MainLoader/MainLoader';
import { SystemButtons } from './SystemButtons/SystemButtons';
import { LanguageSwitcher } from './SystemButtons/components/LanguageSwitcher/LanguageSwitcher';
import { NavLinksGroup } from './SystemButtons/components/NavLinksGroup/NavLinksGroup';
import { NavLink } from './SystemButtons/components/NavLinksGroup/components/NavLink/NavLink';
import { ThemeSwitcher } from './SystemButtons/components/ThemeSwitcher/ThemeSwitcher';
import { useLinksGroup } from 'hooks/useLinksGroup';

interface Props {
  socket: WebSocketType;
}

export const App: FC<Props> = memo(({ socket }) => {
  const dispatch = useAppDispatch();
  const routes = useRoutes();
  const localStorageSave = useLocalStorageSave();
  const localStorageLoad = useLocalStorageLoad();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isAuth = useAppSelector(getIsAuth);
  const isAuthModalActive = useAppSelector(getIsAuthModalActive);
  const recoveryCodeTimerTimestamp = useAppSelector(getRecoveryCodeTimerTimestamp);
  const recoverSessionTimerTimestamp = useAppSelector(getRecoverSessionTimerTimestamp);
  const recoverySessionId = useAppSelector(getRecoverySessionId);
  const isPasswordRecoveryMode = useAppSelector(getIsPasswordRecoveryMode);
  const isPasswordRecoveryProcessStarted = useAppSelector(getIsPasswordRecoveryProcessStarted);
  const passwordRecoveringEmail = useAppSelector(getPasswordRecoveringEmail);
  const { isAssistantLinksGroupActive, isInfoLinksGroupActive, isSignInLinksGroupActive } =
    useLinksGroup();

  const clearPasswordRecoveringData = useCallback(() => {
    recoverySessionId && dispatch(setRecoverySessionId(null));
    recoveryCodeTimerTimestamp && dispatch(setRecoveryCodeTimerTimestamp(null));
    recoverSessionTimerTimestamp && dispatch(setRecoverSessionTimerTimestamp(null));
    isPasswordRecoveryMode && dispatch(setIsPasswordRecoveryMode(false));
    isPasswordRecoveryProcessStarted && dispatch(setIsPasswordRecoveryProcessStarted(false));
    passwordRecoveringEmail && dispatch(setPasswordRecoveringEmail(null));
  }, [
    dispatch,
    isPasswordRecoveryMode,
    isPasswordRecoveryProcessStarted,
    passwordRecoveringEmail,
    recoverSessionTimerTimestamp,
    recoveryCodeTimerTimestamp,
    recoverySessionId,
  ]);

  const websocketsConnectionHandler = () => {
    console.log('WebSocket connection has been established...');
  };

  const updateOnlineUsersNicknames = useCallback(
    (onlineUsersNicknames: Array<string>) => {
      dispatch(setOnlineUsersNicknames(onlineUsersNicknames));
    },
    [dispatch],
  );

  useEffect(() => {
    socket.on('connect', websocketsConnectionHandler);
    socket.on('onlineUsersUpdate', updateOnlineUsersNicknames);
    return () => {
      socket.off('connect', websocketsConnectionHandler);
      socket.off('onlineUsersUpdate', updateOnlineUsersNicknames);
    };
  }, [socket, updateOnlineUsersNicknames]);

  useEffect(() => {
    document.documentElement.lang = currentLanguage === 'ru' ? 'ru' : 'en';
  }, [currentLanguage]);

  useEffect(() => {
    localStorageLoad();
  }, [localStorageLoad]);

  useEffect((): void => {
    if (isAuth) {
      isAuthModalActive && dispatch(setIsAuthModalActive(false));
      clearPasswordRecoveringData();
    }
    localStorageSave();
  }, [clearPasswordRecoveringData, dispatch, isAuth, isAuthModalActive, localStorageSave]);
  return (
    <>
      <SystemButtons>
        <ThemeSwitcher />
        <NavLinksGroup
          mainChild={
            <NavLink isMainLinkInGroup isAppLink linkName="mySportCap" linkSrc={'assistant'} />
          }
          isNavLinksGroupCurrent={isAssistantLinksGroupActive}
        />
        <NavLinksGroup
          mainChild={<NavLink isMainLinkInGroup linkName="signInCap" linkSrc={'signin'} />}
          isNavLinksGroupCurrent={isSignInLinksGroupActive}
        >
          <NavLink linkName="signUpCap" linkSrc={'signup'} />
        </NavLinksGroup>
        <NavLinksGroup
          mainChild={<NavLink isMainLinkInGroup linkName="infoCap" linkSrc={'info'} />}
          isNavLinksGroupCurrent={isInfoLinksGroupActive}
        >
          <NavLink linkName="projectsCap" linkSrc={'projects'} />
          <NavLink linkName="supportCap" linkSrc={'support'} />
        </NavLinksGroup>
        <LanguageSwitcher />
      </SystemButtons>
      <MainLoader />
      {routes}
    </>
  );
});
