import { FC, memo, useEffect, useRef } from 'react';

import { Copyright } from './components/Copyright/Copyright';
import { HomePage } from './pages/HomePage/HomePage';
import { CurrentlyViewedPageType } from 'redux/types';
import { useAppDispatch } from 'redux/hooks';
import { setCurrentlyViewedPage } from 'redux/slices/main/exports';
import { AssistantPage } from './pages/AssistantPage/AssistantPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { RegistrationPage } from './pages/RegistrationPage/RegistrationPage';
import { InfoPage } from './pages/InfoPage/InfoPage';
import { SupportPage } from './pages/SupportPage/SupportPage';
import { ProjectsPage } from './pages/ProjectsPage/ProjectsPage';
import styles from './MainPageRemastered.module.scss';

interface Props {
  currentRoute: CurrentlyViewedPageType;
}

export const MainPageRemastered: FC<Props> = memo(({ currentRoute }) => {
  const minPageRemasteredRef = useRef(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setCurrentlyViewedPage(currentRoute));
  }, [currentRoute, dispatch]);
  return (
    <div className={styles.MainPageRemastered} ref={minPageRemasteredRef}>
      <HomePage currentlyViewedPage={currentRoute} />
      <AssistantPage currentlyViewedPage={currentRoute} />
      <LoginPage currentlyViewedPage={currentRoute} />
      <RegistrationPage currentlyViewedPage={currentRoute} />
      <InfoPage currentlyViewedPage={currentRoute} />
      <SupportPage currentlyViewedPage={currentRoute} />
      <ProjectsPage currentlyViewedPage={currentRoute} />
      <Copyright />
    </div>
  );
});
