import { FC, memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';
import { getCurrentLanguage, getCurrentlyViewedPage } from 'redux/slices/main/exports';
import { language } from 'utils/translation';
import { CurrentlyViewedPageType } from 'redux/types';
import { useAnimateHighlighting } from './hooks/useAnimate';
import { classNames } from 'helpers/classNames';
import styles from './NavLink.module.scss';

interface Props {
  linkName: string;
  linkSrc: CurrentlyViewedPageType;
  isMainLinkInGroup?: boolean;
  isAppLink?: boolean;
  isNavLinksGroupCurrent?: boolean;
}

export const NavLink: FC<Props> = memo(
  ({ linkName, linkSrc, isNavLinksGroupCurrent, isAppLink, isMainLinkInGroup }) => {
    const navLinkRef = useRef(null);
    const navigate = useNavigate();
    const currentLanguage = useAppSelector(getCurrentLanguage);
    const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
    const isThisPageCurrent = currentlyViewedPage === linkSrc;
    const isVisible = isMainLinkInGroup ? true : isNavLinksGroupCurrent;
    useAnimateHighlighting(isThisPageCurrent, isVisible, navLinkRef);

    const changeCurrentlyViewedPage = () => {
      if (!isVisible) return;
      navigate(`/${linkSrc}`);
    };
    return (
      <div
        className={classNames(styles.NavLink, [], { [styles.appLink]: isAppLink })}
        ref={navLinkRef}
      >
        <span onClick={changeCurrentlyViewedPage}>{language(currentLanguage, linkName)}</span>
      </div>
    );
  },
);
