import { FC, memo } from 'react';

import styles from './LanguageItem.module.scss';
import { CurrentLanguageType } from 'types/types';
import { classNames } from 'helpers/classNames';

interface Props {
  languageData: CurrentLanguageType;
}

export const LanguageItem: FC<Props> = memo(({ languageData }) => {
  return (
    <div className={classNames(styles.LanguageItem, ['language-item'])}>
      <span>{languageData}</span>
    </div>
  );
});
