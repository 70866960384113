import { FC, memo, useRef } from 'react';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import styles from './ProjectsPage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const ProjectsPage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const projectsPageRef = useRef(null);
  useAnimateAppearing('projects', currentlyViewedPage, projectsPageRef);
  return (
    <div className={styles.ProjectsPage} ref={projectsPageRef}>
      <span style={{ fontWeight: 'bold' }}>projects</span>
    </div>
  );
});
