import { EMPTY_STRING } from 'constants/constants';
import { UserState } from './types';

export const userSliceInitialState: UserState = {
  isAuth: false,
  token: null,
  userId: null,
  userEmail: null,
  userNickname: null,
  userRole: null,
  isDoctor: false,
  isPatient: false,
  isPetOwner: false,
  isVeterinarian: false,
  isSportsmen: false,
  isTrainer: false,
  userBirthDateTimestamp: null,
  userCity: null,
  userCountry: null,
  userFirstName: null,
  userLastName: null,
  userAvatar: undefined,
  isEmailConfirmed: false,
  userRestrictionsLevel: undefined,
  userRestrictionsTimestamp: undefined,
  selectedRegistrationEmail: EMPTY_STRING,
  authMessage: null,
  isEmailChecking: false,
  authStatus: 'idle',
  updateStatus: 'idle',
  processingStatus: 'idle',
  recoveryCodeTimerTimestamp: null,
  recoverSessionTimerTimestamp: null,
  recoverySessionId: null,
  myAssessments: [],
  assessmentsToApprove: [],
  feedbacks: [],
};
