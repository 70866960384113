import { FC, memo } from 'react';

import { ReactChildrenType } from 'types/types';
import styles from './SystemButtons.module.scss';

interface Props {
  children?: ReactChildrenType;
}

export const SystemButtons: FC<Props> = memo(({ children }) => {
  return <div className={styles.SystemButtons}>{children}</div>;
});
