import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestRESTMethodsEnum } from '../types';
import { Nullable, Undefinable } from '../../types/types';
import { SEARCH_USERS_URL } from './constants';
import { ISearchUsersRequest, ISearchUsersResponse } from './types';
import { universalFetch } from '../utils';

// search users
export const searchUsersAsync = createAsyncThunk(
  'main/search-users',
  async (data: ISearchUsersRequest): Promise<Nullable<ISearchUsersResponse>> => {
    const { lang, searchKey, token } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    params.set('searchKey', searchKey);
    const searchUsersResponse: Undefinable<Response> = await universalFetch(
      `${SEARCH_USERS_URL}?${params}`,
      RequestRESTMethodsEnum.GET,
      undefined,
      token,
    );
    if (searchUsersResponse) {
      return (await searchUsersResponse.json()) as ISearchUsersResponse;
    }
    return null;
  },
);
