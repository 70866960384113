import { ASSISTANT_GROUP_LINKS, SIGN_IN_GROUP_LINKS, INFO_GROUP_LINKS } from 'constants/constants';
import { useAppSelector } from 'redux/hooks';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import { CurrentlyViewedPageType } from 'redux/types';

export const useLinksGroup = () => {
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isThisLinksGroupActive = (links: Array<CurrentlyViewedPageType>) => {
    return links.includes(currentlyViewedPage);
  };
  return {
    isAssistantLinksGroupActive: isThisLinksGroupActive(ASSISTANT_GROUP_LINKS),
    isSignInLinksGroupActive: isThisLinksGroupActive(SIGN_IN_GROUP_LINKS),
    isInfoLinksGroupActive: isThisLinksGroupActive(INFO_GROUP_LINKS),
  };
};
