import { RootState } from 'redux/store';

import { userSlice } from './userSlice';

// setters
export const {
  actions: {
    setAssessmentsToApprove,
    setAuthMessage,
    setFeedbacks,
    setIsAuth,
    setIsDoctor,
    setIsEmailChecking,
    setIsEmailConfirmed,
    setIsPatient,
    setIsPetOwner,
    setIsSportsmen,
    setIsTrainer,
    setIsVeterinarian,
    setMyAssessments,
    setRecoverSessionTimerTimestamp,
    setRecoveryCodeTimerTimestamp,
    setRecoverySessionId,
    setSelectedRegistrationEmil,
    setToken,
    setUserAvatar,
    setUserBirthDateTimestamp,
    setUserCity,
    setUserCountry,
    setUserEmail,
    setUserFirstName,
    setUserId,
    setUserLastName,
    setUserNickname,
    setUserRestrictionsLevel,
    setUserRestrictionsTimestamp,
    setUserRole,
  },
} = userSlice;

// getters
export const getIsAuth = ({ user: { isAuth } }: RootState) => isAuth;
export const getToken = ({ user: { token } }: RootState) => token;
export const getUserId = ({ user: { userId } }: RootState) => userId;
export const getUserEmail = ({ user: { userEmail } }: RootState) => userEmail;
export const getUserNickname = ({ user: { userNickname } }: RootState) => userNickname;
export const getIsDoctor = ({ user: { isDoctor } }: RootState) => isDoctor;
export const getIsPatient = ({ user: { isPatient } }: RootState) => isPatient;
export const getIsPetOwner = ({ user: { isPetOwner } }: RootState) => isPetOwner;
export const getIsVeterinarian = ({ user: { isVeterinarian } }: RootState) => isVeterinarian;
export const getIsSportsmen = ({ user: { isSportsmen } }: RootState) => isSportsmen;
export const getIsTrainer = ({ user: { isTrainer } }: RootState) => isTrainer;
export const getUserCity = ({ user: { userCity } }: RootState) => userCity;
export const getUserCountry = ({ user: { userCountry } }: RootState) => userCountry;
export const getUserFirstName = ({ user: { userFirstName } }: RootState) => userFirstName;
export const getUserLastName = ({ user: { userLastName } }: RootState) => userLastName;
export const getUserAvatar = ({ user: { userAvatar } }: RootState) => userAvatar;
export const getIsEmailConfirmed = ({ user: { isEmailConfirmed } }: RootState) => isEmailConfirmed;
export const getUserBirthDateTimestamp = ({ user: { userBirthDateTimestamp } }: RootState) =>
  userBirthDateTimestamp;
export const getUserRestrictionsLevel = ({ user: { userRestrictionsLevel } }: RootState) =>
  userRestrictionsLevel;
export const getUserRestrictionsTimestamp = ({ user: { userRestrictionsTimestamp } }: RootState) =>
  userRestrictionsTimestamp;
export const getUserRole = ({ user: { userRole } }: RootState) => userRole;
export const getIsEmailChecking = ({ user: { isEmailChecking } }: RootState) => isEmailChecking;
export const getSelectedRegistrationEmail = ({ user: { selectedRegistrationEmail } }: RootState) =>
  selectedRegistrationEmail;
export const getAuthMessage = ({ user: { authMessage } }: RootState) => authMessage;
export const getAuthStatus = ({ user: { authStatus } }: RootState) => authStatus;
export const getUpdateStatus = ({ user: { updateStatus } }: RootState) => updateStatus;
export const getProcessingStatus = ({ user: { processingStatus } }: RootState) => processingStatus;
export const getRecoveryCodeTimerTimestamp = ({
  user: { recoveryCodeTimerTimestamp },
}: RootState) => recoveryCodeTimerTimestamp;
export const getRecoverSessionTimerTimestamp = ({
  user: { recoverSessionTimerTimestamp },
}: RootState) => recoverSessionTimerTimestamp;
export const getRecoverySessionId = ({ user: { recoverySessionId } }: RootState) =>
  recoverySessionId;
export const getMyAssessments = ({ user: { myAssessments } }: RootState) => myAssessments;
export const getAssessmentsToApprove = ({ user: { assessmentsToApprove } }: RootState) =>
  assessmentsToApprove;
export const getFeedbacks = ({ user: { feedbacks } }: RootState) => feedbacks;
