import { FC } from 'react';

import styles from './DarkThemeButton.module.scss';

export const DarkThemeButton: FC = () => {
  return (
    <div className={styles.DarkThemeButton}>
      <div className={styles.darkCircle}></div>
      <div className={styles.mainCircle}></div>
    </div>
  );
};
