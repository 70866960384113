import { FC } from 'react';

import { classNames } from 'helpers/classNames';
import styles from './ErrorPage.module.scss';

interface Props {
  errorData: Error;
}

export const ErrorPage: FC<Props> = ({ errorData: { message } }) => {
  return (
    <div className={classNames(styles.ErrorPage)}>
      <div className={classNames(styles['error-container'])}>
        <h1>Error:</h1>
        <p>{`"${message}"`}</p>
      </div>
    </div>
  );
};
