import { MutableRefObject } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { getIsLanguageSwitcherRolled } from 'redux/slices/main/exports';
import { MAIN_THEME_COLOR, ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateHighlighting = (
  isCurrentLanguage: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const isLanguageSwitcherRolled = useAppSelector(getIsLanguageSwitcherRolled);
  const NOT_ACTIVE_COLOR = '#bbb';
  const selectedColor = isCurrentLanguage ? MAIN_THEME_COLOR : NOT_ACTIVE_COLOR;
  const cursorType = isCurrentLanguage || !isLanguageSwitcherRolled ? 'default' : 'pointer';
  const { timeline: highlightingTimeline } = useGSAPTimeline(false, undefined, scopeElement, [
    isCurrentLanguage,
  ]);
  const HIGHLIGHTING_LANGUAGE_DURATION = 0.3;
  highlightingTimeline &&
    highlightingTimeline
      .to(scopeElement.current, {
        color: selectedColor,
        duration: HIGHLIGHTING_LANGUAGE_DURATION,
      })
      .to(
        scopeElement.current,
        {
          cursor: cursorType,
          duration: ZERO_GSAP_DURATION,
        },
        '<',
      );
};
