import { FC, memo, useRef } from 'react';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import styles from './LoginPage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const LoginPage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const loginPageRef = useRef(null);
  useAnimateAppearing('signin', currentlyViewedPage, loginPageRef);
  return (
    <div className={styles.LoginPage} ref={loginPageRef}>
      <span style={{ fontWeight: 'bold' }}>login</span>
    </div>
  );
});
