import { createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { universalFetch } from '../utils';
import { Nullable, Undefinable } from 'types/types';
import {
  IDeleteUserAccountRequestData,
  IEmailAvailabilityCheckRequest,
  IEmailAvailabilityCheckResponse,
  IGeneralResponse,
  IGetOneUserResponse,
  IRecoveryCodeCheckRequestData,
  IRecoveryCodeCheckResponse,
  IRecoverySessionCheckRequestData,
  ITokenDecodeData,
  IUpdateUserAvatarRequestData,
  IUpdateUserInformationRequestData,
  IUpdateUserPasswordRequestData,
  IUpdateUserRoleRequestData,
  IUpdateUserSpecializationRequestData,
  IUserAuthCheckRequest,
  IUserAuthResponse,
  IUserLoginRequest,
  IUserMasteryUpdateRequestData,
  IUserPasswordRestoreRequestData,
  IUserPasswordRestoreResponse,
  IUserRegistrationRequest,
} from './types';
import { RequestRESTMethodsEnum } from '../types';
import {
  CHECK_EMAIL_AVAILABILITY_URL,
  DELETE_USER_ACCOUNT_URL,
  GET_ONE_USER_INFO_URL,
  PASSWORD_RESTORE_URL,
  RECOVERY_CODE_CHECK_URL,
  RECOVERY_PASSWORD_CHANGE_URL,
  UPDATE_USER_AVATAR_URL,
  UPDATE_USER_INFORMATION_URL,
  UPDATE_USER_MASTERY_URL,
  UPDATE_USER_PASSWORD_URL,
  UPDATE_USER_ROLE_URL,
  UPDATE_USER_SPECIALIZATION_URL,
  USER_AUTHORIZATION_CHECK_URL,
  USER_LOGIN_URL,
  USER_REGISTRATION_URL,
} from './constants';

// authorization thunks
// check registration email availability
export const checkEmailAvailabilityAsync = createAsyncThunk(
  'user/email-check',
  async (
    data: IEmailAvailabilityCheckRequest,
  ): Promise<Nullable<IEmailAvailabilityCheckResponse>> => {
    const checkEmailAvailabilityResponse: Undefinable<Response> = await universalFetch(
      CHECK_EMAIL_AVAILABILITY_URL,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(data),
    );
    if (checkEmailAvailabilityResponse) {
      return (await checkEmailAvailabilityResponse.json()) as IEmailAvailabilityCheckResponse;
    }
    return null;
  },
);

// registration thunk
export const userRegistrationAsync = createAsyncThunk(
  'user/registration',
  async (data: IUserRegistrationRequest): Promise<Nullable<IUserAuthResponse>> => {
    const createUserResponse: Undefinable<Response> = await universalFetch(
      USER_REGISTRATION_URL,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(data),
    );
    if (createUserResponse) {
      if (!createUserResponse.ok) {
        return (await createUserResponse.json()) as IUserAuthResponse;
      } else {
        return (await createUserResponse.json()) as IUserAuthResponse;
      }
    }
    return null;
  },
);

// login thunk
export const userLoginAsync = createAsyncThunk(
  'user/login',
  async (data: IUserLoginRequest): Promise<Nullable<IUserAuthResponse | IGetOneUserResponse>> => {
    const { lang } = data;
    const loginUserResponse: Undefinable<Response> = await universalFetch(
      USER_LOGIN_URL,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(data),
    );
    if (loginUserResponse) {
      if (!loginUserResponse.ok) {
        return (await loginUserResponse.json()) as IUserAuthResponse;
      } else {
        const loginUserResponseData: IUserAuthResponse = await loginUserResponse.json();
        const { token, message } = loginUserResponseData;
        if (token) {
          const params = new URLSearchParams();
          params.set('lang', lang);
          const tokenDecodedData: ITokenDecodeData = jwtDecode(token);
          const getOneUserResponse: Undefinable<Response> = await universalFetch(
            `${GET_ONE_USER_INFO_URL(tokenDecodedData.id)}?${params}`,
            RequestRESTMethodsEnum.GET,
            undefined,
            token,
          );
          if (getOneUserResponse) {
            const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
            getOneUserResponseData.token = token;
            getOneUserResponseData.message = message;
            return getOneUserResponseData;
          }
        }
      }
    }
    return null;
  },
);

// auth check thunk
export const userAuthCheckAsync = createAsyncThunk(
  'user/authorization-check',
  async (
    data: IUserAuthCheckRequest,
  ): Promise<Nullable<IUserAuthResponse | IGetOneUserResponse>> => {
    const { currentTokenObject, lang } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const authCheckResponse: Undefinable<Response> = await universalFetch(
      `${USER_AUTHORIZATION_CHECK_URL}?${params}`,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(currentTokenObject),
    );
    if (authCheckResponse) {
      if (!authCheckResponse.ok) {
        const authCheckResponseData: IUserAuthResponse = await authCheckResponse.json();
        return authCheckResponseData;
      } else {
        const authCheckResponseData: IUserAuthResponse = await authCheckResponse.json();
        const { token, message } = authCheckResponseData;
        if (token) {
          const tokenDecodedData: ITokenDecodeData = jwtDecode(token);
          const getOneUserResponse: Undefinable<Response> = await universalFetch(
            `${GET_ONE_USER_INFO_URL(tokenDecodedData.id)}?${params}`,
            RequestRESTMethodsEnum.GET,
            undefined,
            token,
          );
          if (getOneUserResponse) {
            const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
            getOneUserResponseData.token = token;
            getOneUserResponseData.message = message;
            return getOneUserResponseData;
          }
        }
      }
    }
    return null;
  },
);

// update specified user avatar
export const updateUserAvatarAsync = createAsyncThunk(
  'user/update/avatar',
  async (
    data: IUpdateUserAvatarRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetOneUserResponse>> => {
    const { lang, token, userId, requestData } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const updateUserResponse: Undefinable<Response> = await universalFetch(
      `${UPDATE_USER_AVATAR_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      requestData,
      token,
    );
    if (updateUserResponse) {
      const updateUserResponseData: IGeneralResponse = await updateUserResponse.json();
      if (!updateUserResponse.ok) {
        const { status } = updateUserResponse;
        updateUserResponseData.statusCode = status;
        return updateUserResponseData;
      } else {
        const getOneUserResponse: Undefinable<Response> = await universalFetch(
          `${GET_ONE_USER_INFO_URL(userId)}?${params}`,
          RequestRESTMethodsEnum.GET,
          undefined,
          token,
        );
        if (getOneUserResponse) {
          const { status } = getOneUserResponse;
          const { message } = updateUserResponseData;
          const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
          getOneUserResponseData.statusCode = status;
          getOneUserResponseData.message = message;
          return getOneUserResponseData;
        }
      }
    }
    return null;
  },
);

// update specified user specialization
export const updateUserSpecializationAsync = createAsyncThunk(
  'user/update/specialization',
  async (
    data: IUpdateUserSpecializationRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetOneUserResponse>> => {
    const { lang, token, userId, isPatient, isPetOwner, isSportsmen } = data;
    const requestData = { isPatient, isPetOwner, isSportsmen };
    const params = new URLSearchParams();
    params.set('lang', lang);
    const updateUserResponse: Undefinable<Response> = await universalFetch(
      `${UPDATE_USER_SPECIALIZATION_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      JSON.stringify(requestData),
      token,
    );
    if (updateUserResponse) {
      const updateUserResponseData: IGeneralResponse = await updateUserResponse.json();
      if (!updateUserResponse.ok) {
        const { status } = updateUserResponse;
        updateUserResponseData.statusCode = status;
        return updateUserResponseData;
      } else {
        const getOneUserResponse: Undefinable<Response> = await universalFetch(
          `${GET_ONE_USER_INFO_URL(userId)}?${params}`,
          RequestRESTMethodsEnum.GET,
          undefined,
          token,
        );
        if (getOneUserResponse) {
          const { status } = getOneUserResponse;
          const { message } = updateUserResponseData;
          const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
          getOneUserResponseData.statusCode = status;
          getOneUserResponseData.message = message;
          return getOneUserResponseData;
        }
      }
    }
    return null;
  },
);

// update specified user mastery
export const updateUserMasteryAsync = createAsyncThunk(
  'user/update/mastery',
  async (
    data: IUserMasteryUpdateRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetOneUserResponse>> => {
    const { lang, requestData, token, userId } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const updateUserMasteryResponse: Undefinable<Response> = await universalFetch(
      `${UPDATE_USER_MASTERY_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      requestData,
      token,
    );
    if (updateUserMasteryResponse) {
      const updateUserResponseData: IGeneralResponse = await updateUserMasteryResponse.json();
      if (!updateUserMasteryResponse.ok) {
        const { status } = updateUserMasteryResponse;
        updateUserResponseData.statusCode = status;
        return updateUserResponseData;
      } else {
        const getOneUserResponse: Undefinable<Response> = await universalFetch(
          `${GET_ONE_USER_INFO_URL(userId)}?${params}`,
          RequestRESTMethodsEnum.GET,
          undefined,
          token,
        );
        if (getOneUserResponse) {
          const { status } = getOneUserResponse;
          const { message } = updateUserResponseData;
          const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
          getOneUserResponseData.statusCode = status;
          getOneUserResponseData.message = message;
          return getOneUserResponseData;
        }
      }
    }
    return null;
  },
);

// update the user password
export const updateUserPasswordAsync = createAsyncThunk(
  'user/update/password',
  async (data: IUpdateUserPasswordRequestData): Promise<Nullable<IGeneralResponse>> => {
    const { lang, token, userId, currentPassword, password } = data;
    const requestData = { currentPassword, password };
    const params = new URLSearchParams();
    params.set('lang', lang);
    const updateUserResponse: Undefinable<Response> = await universalFetch(
      `${UPDATE_USER_PASSWORD_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      JSON.stringify(requestData),
      token,
    );
    if (updateUserResponse) {
      const updateUserResponseData: IGeneralResponse = await updateUserResponse.json();
      const { status } = updateUserResponse;
      updateUserResponseData.statusCode = status;
      return updateUserResponseData;
    }
    return null;
  },
);

// update specified user role
export const updateUserRoleAsync = createAsyncThunk(
  'user/update/role',
  async (
    data: IUpdateUserRoleRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetOneUserResponse>> => {
    const { lang, token, userId, role } = data;
    const requestData = { role };
    const params = new URLSearchParams();
    params.set('lang', lang);
    const updateUserResponse: Undefinable<Response> = await universalFetch(
      `${UPDATE_USER_ROLE_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      JSON.stringify(requestData),
      token,
    );
    if (updateUserResponse) {
      const updateUserResponseData: IGeneralResponse = await updateUserResponse.json();
      if (!updateUserResponse.ok) {
        const { status } = updateUserResponse;
        updateUserResponseData.statusCode = status;
        return updateUserResponseData;
      } else {
        const getOneUserResponse: Undefinable<Response> = await universalFetch(
          `${GET_ONE_USER_INFO_URL(userId)}?${params}`,
          RequestRESTMethodsEnum.GET,
          undefined,
          token,
        );
        if (getOneUserResponse) {
          const { status } = getOneUserResponse;
          const { message } = updateUserResponseData;
          const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
          getOneUserResponseData.statusCode = status;
          getOneUserResponseData.message = message;
          return getOneUserResponseData;
        }
      }
    }
    return null;
  },
);

// update specified user information
export const updateUserInformationAsync = createAsyncThunk(
  'user/update/information',
  async (
    data: IUpdateUserInformationRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetOneUserResponse>> => {
    const {
      lang,
      token,
      userId,
      nickname,
      email,
      city,
      country,
      firstName,
      lastName,
      birthDateTimestamp,
    } = data;
    const requestData = { nickname, email, city, country, firstName, lastName, birthDateTimestamp };
    const params = new URLSearchParams();
    params.set('lang', lang);
    const updateUserResponse: Undefinable<Response> = await universalFetch(
      `${UPDATE_USER_INFORMATION_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      JSON.stringify(requestData),
      token,
    );
    if (updateUserResponse) {
      const updateUserResponseData: IGeneralResponse = await updateUserResponse.json();
      if (!updateUserResponse.ok) {
        const { status } = updateUserResponse;
        updateUserResponseData.statusCode = status;
        return updateUserResponseData;
      } else {
        const getOneUserResponse: Undefinable<Response> = await universalFetch(
          `${GET_ONE_USER_INFO_URL(userId)}?${params}`,
          RequestRESTMethodsEnum.GET,
          undefined,
          token,
        );
        if (getOneUserResponse) {
          const { status } = getOneUserResponse;
          const { message } = updateUserResponseData;
          const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
          getOneUserResponseData.statusCode = status;
          getOneUserResponseData.message = message;
          return getOneUserResponseData;
        }
      }
    }
    return null;
  },
);

// delete specified user
export const deleteUserAccountAsync = createAsyncThunk(
  'user/delete',
  async (data: IDeleteUserAccountRequestData): Promise<Nullable<IGeneralResponse>> => {
    const { lang, token, userId } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const deleteUserAccountResponse: Undefinable<Response> = await universalFetch(
      `${DELETE_USER_ACCOUNT_URL(userId)}?${params}`,
      RequestRESTMethodsEnum.DELETE,
      undefined,
      token,
    );
    if (deleteUserAccountResponse) {
      const deleteUserAccountResponseData: IGeneralResponse =
        await deleteUserAccountResponse.json();
      const { status } = deleteUserAccountResponse;
      deleteUserAccountResponseData.statusCode = status;
      return deleteUserAccountResponseData;
    }
    return null;
  },
);

// recover user password
export const recoverUserPasswordAsync = createAsyncThunk(
  'user/password/recover',
  async (
    data: IUserPasswordRestoreRequestData,
  ): Promise<Nullable<IUserPasswordRestoreResponse>> => {
    const { lang, email } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const requestData = { email };
    const recoverUserPasswordResponse: Undefinable<Response> = await universalFetch(
      `${PASSWORD_RESTORE_URL}?${params}`,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(requestData),
      undefined,
    );
    if (recoverUserPasswordResponse) {
      const recoverUserPasswordResponseData: IUserPasswordRestoreResponse =
        await recoverUserPasswordResponse.json();
      const { status } = recoverUserPasswordResponse;
      recoverUserPasswordResponseData.statusCode = status;
      return recoverUserPasswordResponseData;
    }
    return null;
  },
);

// check the password recovering code
export const checkPasswordRecoveringCodeAsync = createAsyncThunk(
  'user/password/check-code',
  async (data: IRecoveryCodeCheckRequestData): Promise<Nullable<IRecoveryCodeCheckResponse>> => {
    const { lang, email, recoveryCode } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const requestData = { email, recoveryCode };
    const checkPasswordRecoveringCodeResponse: Undefinable<Response> = await universalFetch(
      `${RECOVERY_CODE_CHECK_URL}?${params}`,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(requestData),
      undefined,
    );
    if (checkPasswordRecoveringCodeResponse) {
      const checkPasswordRecoveringCodeResponseData: IRecoveryCodeCheckResponse =
        await checkPasswordRecoveringCodeResponse.json();
      const { status } = checkPasswordRecoveringCodeResponse;
      checkPasswordRecoveringCodeResponseData.statusCode = status;
      return checkPasswordRecoveringCodeResponseData;
    }
    return null;
  },
);

// change user password in recovery mode
export const recoveryUserPasswordChangeAsync = createAsyncThunk(
  'user/password/recovery-change',
  async (
    data: IRecoverySessionCheckRequestData,
  ): Promise<Nullable<IUserAuthResponse | IGetOneUserResponse>> => {
    const { lang, email, newPassword, recoverySessionId } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const requestData = { email, newPassword, recoverySessionId };
    const recoveryUserPasswordChangeResponse: Undefinable<Response> = await universalFetch(
      `${RECOVERY_PASSWORD_CHANGE_URL}?${params}`,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(requestData),
    );
    if (recoveryUserPasswordChangeResponse) {
      if (!recoveryUserPasswordChangeResponse.ok) {
        return (await recoveryUserPasswordChangeResponse.json()) as IUserAuthResponse;
      } else {
        const recoveryUserPasswordChangeResponseData: IUserAuthResponse =
          await recoveryUserPasswordChangeResponse.json();
        const { token, message } = recoveryUserPasswordChangeResponseData;
        if (token) {
          const tokenDecodedData: ITokenDecodeData = jwtDecode(token);
          const getOneUserResponse: Undefinable<Response> = await universalFetch(
            `${GET_ONE_USER_INFO_URL(tokenDecodedData.id)}?${params}`,
            RequestRESTMethodsEnum.GET,
            undefined,
            token,
          );
          if (getOneUserResponse) {
            const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
            getOneUserResponseData.token = token;
            getOneUserResponseData.message = message;
            return getOneUserResponseData;
          }
        }
      }
    }
    return null;
  },
);
