import {
  CurrentLanguageType,
  HealthSectionNameType,
  ILocalStorageSave,
  Nullable,
  PetsSectionNameType,
  ProjectPath,
  SportSectionNameType,
  Undefinable,
} from 'types/types';
import { RequestRESTMethodType, RequestStatusType } from './types';
import {
  HEALTH_SECTIONS,
  PASSWORD_RECOVERY_SESSION_AWAITING_TIME,
  PETS_SECTIONS,
  RECOVERY_CODE_AWAITING_TIME,
  SPORT_SECTIONS,
} from '../constants/constants';

export const universalFetch = (
  url: string,
  method: RequestRESTMethodType,
  body?: string | FormData,
  token?: string,
): Undefinable<Promise<Response>> => {
  try {
    if (token) {
      if (typeof body !== 'string') {
        return fetch(url, {
          method,
          body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        return fetch(url, {
          method,
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else {
      if (typeof body !== 'string') {
        return fetch(url, {
          method,
          body,
        });
      } else {
        return fetch(url, {
          method,
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
    }
  } catch (exception: unknown) {
    if (exception instanceof Error) {
      console.error('\x1b[40m\x1b[31m\x1b[1m', exception.message);
    }
  }
};

export const getPreloadedState = (): {
  currentLanguage: CurrentLanguageType;
  token: Nullable<string>;
  recoveryCodeTimerTimestamp: Nullable<number>;
  recoverSessionTimerTimestamp: Nullable<number>;
  recoverySessionId: Nullable<string>;
  passwordRecoveringEmail: Nullable<string>;
  authStatus: RequestStatusType;
} => {
  const save: Nullable<string> = localStorage.getItem('deguz-save');
  if (save) {
    const saveData: ILocalStorageSave = JSON.parse(save);
    const { currentLanguage, token, recoverySessionId, passwordRecoveringEmail } = saveData;
    let { recoveryCodeTimerTimestamp, recoverSessionTimerTimestamp } = saveData;
    const currentDateStamp = Date.now();
    recoveryCodeTimerTimestamp =
      currentDateStamp - recoveryCodeTimerTimestamp > RECOVERY_CODE_AWAITING_TIME
        ? null
        : recoveryCodeTimerTimestamp;
    recoverSessionTimerTimestamp =
      currentDateStamp - recoverSessionTimerTimestamp > PASSWORD_RECOVERY_SESSION_AWAITING_TIME
        ? null
        : recoverSessionTimerTimestamp;
    return {
      currentLanguage: currentLanguage || 'en',
      token: token || null,
      recoveryCodeTimerTimestamp: recoveryCodeTimerTimestamp,
      recoverSessionTimerTimestamp: recoverSessionTimerTimestamp,
      recoverySessionId: recoverySessionId || null,
      passwordRecoveringEmail: passwordRecoveringEmail || null,
      authStatus: token ? 'loading' : 'idle',
    };
  } else {
    return {
      currentLanguage:
        navigator.language.includes('ru') || navigator.language.includes('RU') ? 'ru' : 'en',
      token: null,
      recoveryCodeTimerTimestamp: null,
      recoverSessionTimerTimestamp: null,
      recoverySessionId: null,
      passwordRecoveringEmail: null,
      authStatus: 'idle',
    };
  }
};

export const getPreloadedActiveSection = () => {
  const startSectionLocation = location.pathname.split('/')[1] as ProjectPath;
  let startSectionName = location.pathname.split('/')[2];
  switch (startSectionLocation) {
    case 'sports': {
      const identifiedSportSection = SPORT_SECTIONS.find((sportSectionName) =>
        startSectionName.includes(sportSectionName),
      );
      if (identifiedSportSection) {
        startSectionName = identifiedSportSection;
      } else {
        startSectionName = 'diary';
      }
      break;
    }
    case 'pets': {
      const identifiedPetsSection = PETS_SECTIONS.find((petsSectionName) =>
        startSectionName.includes(petsSectionName),
      );
      if (identifiedPetsSection) {
        startSectionName = identifiedPetsSection;
      } else {
        startSectionName = 'cattery';
      }
      break;
    }
    case 'health': {
      const identifiedHealthSection = HEALTH_SECTIONS.find((healthSectionName) =>
        startSectionName.includes(healthSectionName),
      );
      if (identifiedHealthSection) {
        startSectionName = identifiedHealthSection;
      } else {
        startSectionName = 'diary';
      }
    }
  }

  return {
    activeSportSection:
      startSectionLocation === 'sports' ? (startSectionName as SportSectionNameType) : 'diary',
    activePetsSection:
      startSectionLocation === 'pets' ? (startSectionName as PetsSectionNameType) : 'cattery',
    activeHealthSection:
      startSectionLocation === 'health' ? (startSectionName as HealthSectionNameType) : 'diary',
  };
};
