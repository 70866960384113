import { FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { AVAILABLE_LANGUAGES } from 'constants/constants';
import { LanguageItem } from './components/LanguageItem/LanguageItem';
import { RollDownLanguagesMenu } from './components/RollDownLanguagesMenu/RollDownLanguagesMenu';
import { useAnimateRolling, useSliding } from './hooks/useAnimate';
import {
  getIsLanguageSwitcherRolled,
  setIsLanguageSwitcherRolled,
} from 'redux/slices/main/exports';
import styles from './LanguageSwitcher.module.scss';

export const LanguageSwitcher: FC = memo(() => {
  const languageSwitcherRef = useRef<HTMLDivElement>(null);
  const languagesWrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isLanguageSwitcherRolled = useAppSelector(getIsLanguageSwitcherRolled);
  useAnimateRolling(isLanguageSwitcherRolled, languageSwitcherRef);
  useSliding(languagesWrapperRef);

  const handleToggleRollDownMenu = () => {
    dispatch(setIsLanguageSwitcherRolled(!isLanguageSwitcherRolled));
  };
  return (
    <div className={styles.LanguageSwitcher} role="button" ref={languageSwitcherRef}>
      <div
        className={styles.languagesWrapper}
        onClick={handleToggleRollDownMenu}
        ref={languagesWrapperRef}
      >
        {AVAILABLE_LANGUAGES.map((languageData, index) => {
          return <LanguageItem languageData={languageData} key={index} />;
        })}
      </div>
      <RollDownLanguagesMenu />
    </div>
  );
});
