import { FC } from 'react';

import { classNames } from 'helpers/classNames';
import styles from './MainLogoLetter.module.scss';

interface Props {
  letterData: string;
}

export const MainLogoLetter: FC<Props> = ({ letterData }) => {
  return (
    <div className={classNames(styles.MainLogoLetter, ['logo-letter'])}>
      <span>{letterData}</span>
    </div>
  );
};
