import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGeneralRequestWithAuth,
  IGeneralResponse,
  IGetAllAvailableAssessmentsResponse,
  IGetOneUserResponse,
  IHandleAssessmentsApprovementRequestData,
  IRemoveAssessmentRequestData,
} from './types';
import { Nullable, Undefinable } from 'types/types';
import { universalFetch } from 'redux/utils';
import { RequestRESTMethodsEnum } from 'redux/types';
import {
  APPROVE_ASSESSMENT_HANDLE_URL,
  GET_ASSESSMENTS_INFO_URL,
  GET_ONE_USER_INFO_URL,
  REMOVE_MASTERY_ASSESSMENT_URL,
} from './constants';

// get all available assessments to approve
export const getAssessmentsToApproveAsync = createAsyncThunk(
  'user/assessments-get-available',
  async (data: IGeneralRequestWithAuth): Promise<Nullable<IGetAllAvailableAssessmentsResponse>> => {
    const { lang, token } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const getAssessmentsToApproveResponse: Undefinable<Response> = await universalFetch(
      `${GET_ASSESSMENTS_INFO_URL}?${params}`,
      RequestRESTMethodsEnum.GET,
      undefined,
      token,
    );
    if (getAssessmentsToApproveResponse) {
      const getAssessmentsToApproveResponseData =
        (await getAssessmentsToApproveResponse.json()) as IGetAllAvailableAssessmentsResponse;
      const { status } = getAssessmentsToApproveResponse;
      getAssessmentsToApproveResponseData.statusCode = status;
      return getAssessmentsToApproveResponseData;
    }
    return null;
  },
);

// handle the assessment's approvement
export const handleAssessmentsApprovementAsync = createAsyncThunk(
  'user/assessments-handle-approvement',
  async (data: IHandleAssessmentsApprovementRequestData): Promise<Nullable<IGeneralResponse>> => {
    const { lang, token, assessmentId } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const handleAssessmentsApprovementResponse: Undefinable<Response> = await universalFetch(
      `${APPROVE_ASSESSMENT_HANDLE_URL(assessmentId)}?${params}`,
      RequestRESTMethodsEnum.PUT,
      undefined,
      token,
    );
    if (handleAssessmentsApprovementResponse) {
      const handleAssessmentsApprovementResponseData =
        await handleAssessmentsApprovementResponse.json();
      const { status } = handleAssessmentsApprovementResponse;
      handleAssessmentsApprovementResponseData.statusCode = status;
      return handleAssessmentsApprovementResponseData;
    }
    return null;
  },
);

// remove the assessment
export const removeAssessmentAsync = createAsyncThunk(
  'user/assessments-remove',
  async (
    data: IRemoveAssessmentRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetOneUserResponse>> => {
    const { lang, token, userId, assessmentId, userRole } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const removeAssessmentResponse: Undefinable<Response> = await universalFetch(
      `${REMOVE_MASTERY_ASSESSMENT_URL(userId, assessmentId)}?${params}`,
      RequestRESTMethodsEnum.DELETE,
      undefined,
      token,
    );
    if (removeAssessmentResponse) {
      const { status } = removeAssessmentResponse;
      const removeAssessmentResponseData = await removeAssessmentResponse.json();
      if (userRole === 'CREATOR') {
        removeAssessmentResponseData.statusCode = status;
        return removeAssessmentResponseData;
      } else {
        const getOneUserResponse: Undefinable<Response> = await universalFetch(
          `${GET_ONE_USER_INFO_URL(userId)}?${params}`,
          RequestRESTMethodsEnum.GET,
          undefined,
          token,
        );
        if (getOneUserResponse) {
          const { message } = removeAssessmentResponseData;
          const { status } = getOneUserResponse;
          const getOneUserResponseData: IGetOneUserResponse = await getOneUserResponse.json();
          getOneUserResponseData.statusCode = status;
          getOneUserResponseData.message = message;
          return getOneUserResponseData;
        }
      }
    }
    return null;
  },
);
